<template>
  <v-card flat tile>
    <v-tabs v-model="tab" background-color="transparent">
      <v-tab key="settings">
        {{ $t("settings.lang_settings") }}
      </v-tab>

      <v-tab key="open" @click="openPickupMonitor()">
        {{ $t("settings.lang_openPickupMonitor") }}&nbsp;
        <v-icon size="18">mdi-open-in-new</v-icon>
      </v-tab>

      <v-tabs-items touchless v-model="tab">
        <v-tab-item key="settings">
          <PickupMonitorSettings />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import PickupMonitorSettings from "./PickupMonitorSettings.vue";

export default {
  name: "Index",

  components: {
    PickupMonitorSettings,
  },

  data() {
    return {
      tab: 0,
    };
  },

  computed: {
    ...mapState(["api"]),
  },

  methods: {
    openPickupMonitor() {
      let pickupMonitorURL = process.env.VUE_APP_PICKUPMONITOR_URL;

      if (process.env.VUE_APP_HYBRID == "true") {
        if (
          window.location.hostname != "localhost" &&
          window.location.hostname != "devkasse.3pos.de" &&
          window.location.hostname != "kasse.3pos.de"
        ) {
          pickupMonitorURL =
            window.location.protocol +
            "//" +
            window.location.hostname +
            ":8048";
        }
      }
      window.open(
        pickupMonitorURL +
          "?bs=" +
          this.api.auth.posID +
          "&bsid=" +
          this.api.auth.accessString,
        "_blank"
      );
    },
  },
};
</script>
