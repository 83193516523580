<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-switch
          v-model="pickupmon_disableDisplayingOfOrders"
          :label="
            $t(
              'generic.lang_disableDisplayingOfOrdersWithoutOrderbonOrKitchenmonitirAssignment'
            )
          "
          hide-details
          :disabled="loading"
          :loading="loading"
        ></v-switch>
      </v-col>

      <v-col cols="12" align="right">
        <v-btn
          @click="updateSettings"
          :loading="loading"
          :disabled="loading"
          color="primary"
        >
          {{ $t("generic.lang_update") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "../../../config";
import { Events } from "@/plugins/events";

export default {
  name: "PickupMonitorSettings",
  data() {
    return {
      loading: false,
      pickupmon_disableDisplayingOfOrders: false,
    };
  },
  methods: {
    getSettings() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.PICKUPMONITOR.GET)
        .then((res) => {
          if (res.data.success) {
            this.pickupmon_disableDisplayingOfOrders =
              res.data.pickupmon_disableDisplayingOfOrders;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateSettings() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.PICKUPMONITOR.UPDATE, {
          pickupmon_disableDisplayingOfOrders:
            this.pickupmon_disableDisplayingOfOrders,
        })
        .then((res) => {
          if (res.data.success) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_ActionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getSettings();
  },
};
</script>
