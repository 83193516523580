<template>
  <div>
    <page-title
      :heading="$t('settings.lang_pickupMonitor')"
      :subheading="$t('settings.lang_pickupMonitor')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <PickupMonitorIndex></PickupMonitorIndex>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import PickupMonitorIndex from "../../components/settings/pickupMonitor/Index.vue";

export default {
  name: "Pickupmonitor",
  components: {
    PageTitle,
    PickupMonitorIndex,
  },
  data: () => ({
    icon: "pe-7s-monitor icon-gradient bg-tempting-azure",
  }),
};
</script>
